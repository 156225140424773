import { Link } from "@fluentui/react-components";
import "./Welcome.css";
import { Chat24Regular } from "@fluentui/react-icons";

export function Footer() {
  return (
    <div className="footer">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          alignItems: "end",
          gap: "10px",
          marginLeft: "18px",
        }}
      >
        <Link appearance="subtle" href="https://forms.office.com/r/9d6FPx5Xrh" target="_blank">
          Talk to Mavericks
        </Link>
        <Chat24Regular />
      </div>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
        <img src="https://lf-landscape.netlify.app/logos/adfolks-llc.svg" height="17px" alt="App Logo" className="app-logo" />
      </div>
    </div>
  );
}
