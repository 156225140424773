import * as msTeams from "@microsoft/teams-js";
import { sha256 } from "crypto-hash";
import { APIM_BASE } from "./constants";

/**
 * Summarize the text content of the transcript
 *
 * @param {string} transcriptContent Meeting transcript retrieved from Graph API or manually uploaded.
 *
 * @returns {Promise<{actionPlans: string[], insights: string, subject: string, summary: string, agenda: string[]}>}
 * @throws `Error` when response status code is not 200 OK.
 */
export async function summarizeTranscript(transcriptContent) {
  const idToken = await msTeams.authentication.getAuthToken();
  const checksum = await sha256(transcriptContent, { outputFormat: "hex" });
  const response = await fetch(`${APIM_BASE}/openai/summarize-transcript`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "X-API-Version": "v1.1",
      "X-Body-Checksum": `sha256:${checksum}`,
    },
    body: transcriptContent,
    method: "POST",
  });

  const body = await response.json();
  if (!response.ok) {
    throw new Error(body.message);
  }

  return JSON.parse(
    body.rawPayload
      .trim()
      .replace(/^```json|```$/g, "")
      .trim()
  );
}
