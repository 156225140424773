import * as msTeams from "@microsoft/teams-js";
import { APIM_BASE } from "./constants";

/**
 * Get authentication token for Graph APIs
 *
 * @returns {Promise<string>}
 * @throws `Error` when response status code is not 200 OK
 */
export async function getAuthToken() {
  const idToken = await msTeams.authentication.getAuthToken();

  const response = await fetch(`${APIM_BASE}/ms-auth/oauth2/v2.0/token`, {
    method: "GET",
    headers: { Authorization: `Bearer ${idToken}`, "X-API-Version": "v1.0" },
  });

  const body = await response.json();
  if (!response.ok) {
    throw new Error(body.message);
  }

  return body.payload.accessToken;
}
