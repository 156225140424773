import { useContext } from "react";
import { Welcome } from "./Welcome";
import { TeamsFxContext } from "./Context";

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  return (
    <div className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}>
      <Welcome />
    </div>
  );
}
