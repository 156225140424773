import { useContext, useEffect, useState } from "react";
import { Image } from "@fluentui/react-components";
import "./Welcome.css";
import { Meetings } from "./Meetings";
import { useData } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import { Footer } from "./Footer";
import { getAuthToken } from "../services/auth";

export function Welcome() {
  const [authToken, setAuthToken] = useState(null);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });

  const userName = loading || error ? "" : data.displayName;
  useEffect(() => {
    getAuthToken()
      .then((token) => setAuthToken(token))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="welcome-page" style={{ height: "100%", overflow: "auto" }}>
      <div className="narrow-page-padding">
        <div className="narrow-page-content">
          <div className="welcome-image-div">
            <Image className="welcome-image" src="hello.png" />
          </div>
          <h3 className="center">Welcome{userName ? ", " + userName : ""}!</h3>
          <p className="center">Please select a recording enabled meeting</p>

          <div className="tabList">
            <div style={{ width: "100%" }}>
              {authToken != null && <Meetings authToken={authToken} />}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
