import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as msTeams from "@microsoft/teams-js";

(async () => {
  await msTeams.app.initialize();
  msTeams.app.notifyAppLoaded();

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
})();
