import React, { useEffect, useState } from "react";
import { Dropdown, PrimaryButton, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { Avatar, Card, useId, Toaster, useToastController, Toast, ToastTitle } from "@fluentui/react-components";

import "./Meetings.css";
import { ClipboardPasteRegular, CheckmarkCircleRegular, DocumentAddRegular, DismissRegular } from "@fluentui/react-icons";
import { getMeetings, getMeetingTranscript } from "../services/meeting";
import { summarizeTranscript } from "../services/openai";

/** @param {{authToken: string}} props  */
export function Meetings(props) {
  const [selectedMeetingFromDropdown, setSelectedMeetingFromDropdown] = useState(null);
  const [selectedMeetingBasicDetails, setSelectedMeetingBasicDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMeetingSchedule, setIsMeetingSchedule] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [showAll, setShowAll] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [copy, setCopy] = useState(false);
  // eslint-disable-next-line
  const [actionCopy, _setActionCopy] = useState(false);
  const [isUploadTranscript, setIsUploadTranscript] = useState(false);
  const [uplaodTranscriptData, setUplaodTranscriptData] = useState();

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  /** @type {(intent?: "error" | "info" | "success" | "warning", message?: string) => void} */
  const notify = (intent = "success", message = "") =>
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>,
      { intent }
    );

  useEffect(() => {
    if (selectedMeetingFromDropdown && meetings?.length) {
      const details = meetings?.find((item) => item?.id === selectedMeetingFromDropdown?.key);
      setSelectedMeetingBasicDetails(details);
      setShowAll(false);
    }
  }, [selectedMeetingFromDropdown, meetings]);

  useEffect(() => {
    setIsLoading(true);
    getMeetings(props.authToken)
      .then((meetings) => setMeetings(meetings))
      .then(() => notify("success", "Meetings fetched successfully!"))
      .catch((error) => notify("error", error.message))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = async () => {
    setIsUploadTranscript(false);
    setUplaodTranscriptData(undefined);
    if (!selectedMeetingFromDropdown || !selectedMeetingBasicDetails?.onlineMeeting?.id) return;

    const meetingId = selectedMeetingBasicDetails.onlineMeeting.id;
    setIsLoading(true);

    try {
      const transcriptContent = await getMeetingTranscript(meetingId, props.authToken);
      const summary = await summarizeTranscript(transcriptContent);
      setSummaryData(summary);
      setIsMeetingSchedule(true);

      notify("success", "Meeting summary generated from transacript successfully!");
    } catch (error) {
      notify("error", error instanceof Error ? error.message : error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCpoy = () => {
    const cardContent = document.querySelector(".card-main-div");
    if (cardContent) {
      const showLessText = document.getElementById("show-text");
      if (showLessText) {
        showLessText.style.display = "none";
      }
      const copyText = document.getElementById("copy-text");
      if (copyText) {
        copyText.style.display = "none";
      }
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(cardContent);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
      setShowAll(false);
      if (showLessText) {
        showLessText.style.display = "block";
      }
      if (copyText) {
        copyText.style.display = "block";
      }
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (showAll && copy) handleCpoy();
  }, [copy, showAll]);

  const handleFileChange = async (event) => {
    try {
      event.preventDefault();
      const fileElement = event.target.files[0];
      if (!fileElement) {
        return notify("error", "Can't find target element");
      }

      if (!fileElement) {
        return notify("error", "Please select a file.");
      } else if (!/\.(txt|vtt)$/.test(fileElement.name)) {
        return notify("error", "Please select valid VTT file.");
      }

      setFileName(fileElement?.name);
      const transcriptContent = await fileElement.text();
      setSelectedMeetingFromDropdown(null);
      setIsLoading(true);
      if (transcriptContent) {
        setIsUploadTranscript(true);
        const summary = await summarizeTranscript(transcriptContent);
        setUplaodTranscriptData(summary);
        notify("success", "Meeting summary fetched");
      } else {
        notify("error", "Transcript not found");
      }
    } catch (error) {
      notify("error", error instanceof Error ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Toaster position="top-end" pauseOnHover pauseOnWindowBlur timeout={3000} toasterId={toasterId} />
      <div className="meetings-container">
        {(!isLoading || meetings?.length > 0) && (
          <>
            <div className="dropdown-container">
              <Dropdown
                disabled={isLoading}
                className="meeting_drop_down"
                placeholder="Select a meeting"
                options={
                  meetings?.length
                    ? meetings?.map((item) => {
                        return { key: item?.id, text: item?.subject };
                      })
                    : []
                }
                onChange={(event, item) => {
                  setSelectedMeetingFromDropdown(item);
                  setIsMeetingSchedule(false);
                  setIsUploadTranscript(false);
                  setUplaodTranscriptData(undefined);
                  setFileName(null);
                }}
                // styles={{ dropdown: { minWidth: "200px" } }}
              />
              <PrimaryButton
                text="Get Summary"
                onClick={handleButtonClick}
                disabled={!selectedMeetingFromDropdown || isLoading}
                className="summary-button"
              />
            </div>
            <div className="upload-container">
              <label className="custom-file-input">
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                    <DocumentAddRegular fontSize={"30px"} />
                    {fileName ? (
                      <>
                        <Text style={{ color: "#4F52B2", margin: "0 5px" }}>{fileName}</Text>
                        {!isLoading && (
                          <DismissRegular
                            onClick={(event) => {
                              event.preventDefault();
                              setFileName(null);
                              setUplaodTranscriptData(null);
                            }}
                            fontSize={"20px"}
                            style={{ color: "red", margin: "0 5px", cursor: "pointer" }}
                          />
                        )}
                      </>
                    ) : (
                      <Text style={{ color: "red" }}>Upload Transcript</Text>
                    )}
                    {!fileName && (
                      <input
                        disabled={isLoading}
                        style={{ width: 10 }}
                        type="file"
                        id="fileInput"
                        accept=".vtt"
                        onChange={handleFileChange}
                      />
                    )}
                  </div>
                </div>
              </label>
            </div>
          </>
        )}
        {isLoading && (
          <>
            <Spinner size={SpinnerSize.large} className="spinner" />
            {(selectedMeetingFromDropdown && selectedMeetingBasicDetails) || isUploadTranscript ? (
              <p className="loader-text">Request may take around 1 minute.</p>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      {!isLoading &&
        selectedMeetingFromDropdown &&
        selectedMeetingBasicDetails &&
        !uplaodTranscriptData &&
        !isUploadTranscript &&
        (!isMeetingSchedule ? (
          <div className="meeting-details-main" style={{ display: "flex", justifyContent: "center" }}>
            <Card className="meeting-details">
              <Text className="text">
                <b>Meeting Details</b>
              </Text>
              <Text>
                <b>Title:</b> {selectedMeetingBasicDetails?.subject}
              </Text>
              <Text>
                <b>Date & Time: </b>{" "}
                {new Date(
                  new Date(selectedMeetingBasicDetails?.start?.dateTime)?.getTime() - new Date().getTimezoneOffset() * 60 * 1000
                )?.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Text>
              <Text>
                <b>Requested By: </b>
                {selectedMeetingBasicDetails?.organizer?.emailAddress?.name}
              </Text>
              <Text>
                <b>Attendees: </b>
              </Text>
              <div className="attendees">
                {selectedMeetingBasicDetails?.attendees && selectedMeetingBasicDetails?.attendees.length ? (
                  <>
                    {selectedMeetingBasicDetails?.attendees
                      .slice(0, showAll ? selectedMeetingBasicDetails?.attendees.length : 10)
                      .map((attendee, index) => (
                        <div key={index}>
                          <Avatar color="colorful" className="avtar" name={attendee?.emailAddress?.name} />
                          <Text className="attendees-text">{attendee?.emailAddress?.name}</Text>
                        </div>
                      ))}
                    {selectedMeetingBasicDetails?.attendees.length > 10 && !showAll ? (
                      <Text
                        id="show-text"
                        onClick={() => setShowAll(true)}
                        style={{ color: "blue", cursor: "pointer", fontWeight: 600, margin: "10px 0 0 10px" }}
                      >
                        Show More...
                      </Text>
                    ) : (
                      showAll && (
                        <Text
                          id="show-text"
                          onClick={() => setShowAll(false)}
                          style={{ color: "blue", cursor: "pointer", fontWeight: 600, margin: "10px 0 0 10px" }}
                        >
                          Show Less
                        </Text>
                      )
                    )}
                  </>
                ) : (
                  <Text className="no-attendees" style={{ color: "#da3b01", fontWeight: 700 }}>
                    No Attendees
                  </Text>
                )}
              </div>
            </Card>
          </div>
        ) : (
          <>
            <div className="card-main-div">
              <div className="childrenDiv-summary">
                <Card className="summary-card">
                  <div id={"copy-text"} style={{ position: "absolute", right: "20px", color: "black" }}>
                    {copy ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Text style={{ marginRight: "2px" }}>
                          <b>Copied!</b>
                        </Text>
                        <CheckmarkCircleRegular fontSize={18} />
                      </div>
                    ) : (
                      <ClipboardPasteRegular
                        fontSize={25}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowAll(true);
                          setCopy(true);
                        }}
                      />
                    )}
                  </div>
                  <Text className="text">
                    <b>{selectedMeetingBasicDetails?.subject}</b>
                  </Text>
                  {copy && showAll && <br />}
                  <Text style={{ fontStyle: "italic" }}>
                    <b>
                      {new Date(
                        new Date(selectedMeetingBasicDetails?.start?.dateTime)?.getTime() -
                          new Date().getTimezoneOffset() * 60 * 1000
                      )?.toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </b>
                  </Text>
                  {copy && showAll && <br />}
                  <Text className="subText">
                    <b>Attendees</b>
                  </Text>
                  <div className="attendees">
                    {selectedMeetingBasicDetails?.attendees && selectedMeetingBasicDetails?.attendees.length ? (
                      <>
                        {selectedMeetingBasicDetails?.attendees
                          .slice(0, showAll ? selectedMeetingBasicDetails?.attendees.length : 10)
                          .map((attendee, index) => (
                            <div key={index}>
                              <Avatar color="colorful" className="avtar" name={attendee?.emailAddress?.name} />
                              <Text className="attendees-text">{attendee?.emailAddress?.name}</Text>
                            </div>
                          ))}
                        {selectedMeetingBasicDetails?.attendees.length > 10 && !showAll ? (
                          <Text
                            id={"show-text"}
                            onClick={() => setShowAll(true)}
                            style={{ color: "blue", cursor: "pointer", fontWeight: 600, margin: "10px 0 0 10px" }}
                          >
                            Show More...
                          </Text>
                        ) : (
                          showAll && (
                            <Text
                              id={"show-text"}
                              onClick={() => setShowAll(false)}
                              style={{ color: "blue", cursor: "pointer", fontWeight: 600, margin: "10px 0 0 10px" }}
                            >
                              Show Less
                            </Text>
                          )
                        )}
                      </>
                    ) : (
                      <Text className="no-attendees" style={{ color: "#da3b01", fontWeight: 700 }}>
                        No Attendees
                      </Text>
                    )}
                  </div>
                  {copy && showAll && <br />}
                  <Text className="subText">
                    <b>Agenda</b>
                  </Text>
                  <ul className="ul-style">
                    {summaryData?.agenda?.map((agenda, index) => (
                      <li className="list">
                        <Text key={index}>{agenda}</Text>
                      </li>
                    ))}
                  </ul>
                  {copy && showAll && <br />}
                  <Text className="subText">
                    <b>Summary</b>
                  </Text>
                  <Text style={{ marginLeft: "10px" }}>{summaryData?.summary}</Text>
                </Card>
              </div>
              <div style={{ position: "relative" }}>
                <div className="childrenDiv-action">
                  <Card className="action-card">
                    {copy && showAll && <br />}
                    <Text className="text">
                      <b>Action Items</b>
                    </Text>
                    {actionCopy && <br />}
                    <ul className="ul-style">
                      {summaryData?.action_plans &&
                        summaryData?.action_plans?.map((item, index) => (
                          <li className="list">
                            <Text key={index}>{item}</Text>
                          </li>
                        ))}
                    </ul>
                  </Card>
                </div>
                <div className="childrenDiv-insight">
                  <Card className="insights-card">
                    {copy && showAll && <br />}
                    <Text className="text">
                      <b>Insights</b>
                    </Text>
                    <Text>{summaryData?.insights}</Text>
                  </Card>
                </div>
              </div>
            </div>
          </>
        ))}
      {!isLoading && isUploadTranscript && uplaodTranscriptData ? (
        <>
          <div className="card-main-div">
            <div className="childrenDiv-summary">
              <Card className="summary-card">
                <div id={"copy-text"} style={{ position: "absolute", right: "20px", color: "black" }}>
                  {copy ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Text style={{ marginRight: "2px" }}>
                        <b>Copied!</b>
                      </Text>
                      <CheckmarkCircleRegular fontSize={18} />
                    </div>
                  ) : (
                    <ClipboardPasteRegular
                      fontSize={25}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowAll(true);
                        setCopy(true);
                      }}
                    />
                  )}
                </div>
                <Text className="text" style={{ marginRight: "70px" }}>
                  <b>{uplaodTranscriptData?.subject}</b>
                </Text>
                {copy && showAll && <br />}
                <Text className="subText">
                  <b>Agenda</b>
                </Text>
                <ul className="ul-style">
                  {uplaodTranscriptData?.agenda?.map((agenda, index) => (
                    <li className="list">
                      <Text key={index}>{agenda}</Text>
                    </li>
                  ))}
                </ul>
                {copy && showAll && <br />}
                <Text className="subText">
                  <b>Summary</b>
                </Text>
                <Text style={{ marginLeft: "10px" }}>{uplaodTranscriptData?.summary}</Text>
              </Card>
            </div>
            <div style={{ position: "relative" }}>
              <div className="childrenDiv-action">
                <Card className="action-card">
                  {copy && showAll && <br />}
                  <Text className="text">
                    <b>Action Items</b>
                  </Text>
                  {actionCopy && showAll && <br />}
                  <ul className="ul-style">
                    {uplaodTranscriptData?.action_plans &&
                      uplaodTranscriptData?.action_plans?.map((item, index) => (
                        <li className="list">
                          <Text key={index}>{item}</Text>
                        </li>
                      ))}
                  </ul>
                </Card>
              </div>
              <div className="childrenDiv-insight">
                <Card className="insights-card">
                  {copy && showAll && <br />}
                  <Text className="text">
                    <b>Insights</b>
                  </Text>
                  <Text>{uplaodTranscriptData?.insights}</Text>
                </Card>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
