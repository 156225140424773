import { GRAPH_API_BASE } from "./constants";

/**
 * Get all the calendar events that are online meetings (`isOnlineMeeting` is true).
 *
 * @param {string} accessToken Graph API authentication token retrieved from `getAuthToken`.
 * @returns {Promise<(Omit<import("@microsoft/microsoft-graph-types").Event, "onlineMeeting"> & {onlineMeeting: import("@microsoft/microsoft-graph-types").Event["onlineMeeting"] & { id: string }})[]>}
 * @throws `Error` when response status code is not 200 OK
 */
export async function getMeetings(accessToken) {
  const response = await fetch(`${GRAPH_API_BASE}/me/calendar/events`, {
    headers: { Authorization: `Bearer ${accessToken}` },
    method: "GET",
  });

  const body = await response.json();
  if (!response.ok) {
    throw new Error(body.error?.message || "Something went wrong!");
  }

  if (Array.isArray(body.value)) {
    const events = body.value.filter((event) => event.isOnlineMeeting);

    const jobs = [];
    for (const event of events) {
      jobs.push(
        new Promise((resolve) => {
          (async () => {
            try {
              const meetingInfoResponse = await fetch(
                `${GRAPH_API_BASE}/me/onlineMeetings?$filter=JoinWebUrl eq '${event.onlineMeeting.joinUrl}'`,
                {
                  headers: { Authorization: `Bearer ${accessToken}` },
                  method: "GET",
                }
              );

              const body = await meetingInfoResponse.json();
              if (!meetingInfoResponse.ok) {
                // FIXME: Check for forbidden error and return error with the message.
                throw new Error(body.error.message);
              }

              if (body.value && Array.isArray(body.value)) {
                event.onlineMeeting = body.value[0];
              } else {
                event.onlineMeeting = null;
              }
            } catch (error) {
              event.onlineMeeting = null;
            } finally {
              resolve();
            }
          })();
        })
      );
    }

    await Promise.all(jobs);
    return events.filter((event) => event.onlineMeeting != null);
  }

  return [];
}

/**
 * Get the transcript content of the meeting from its id.
 *
 * @param {string} meetingId ID of the meeting selected from the response of `getMeetings`.
 * @param {string} accessToken Graph API authentication token retrieved from `getAuthToken`.
 *
 * @returns {Promise<string>}
 * @throws `Error` when response status code is not 200 OK or there is no transcription of the meeting.
 */
export async function getMeetingTranscript(meetingId, accessToken) {
  const transcriptResponse = await fetch(`${GRAPH_API_BASE}/me/onlineMeetings/${meetingId}/transcripts`, {
    method: "GET",
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const transcriptBody = await transcriptResponse.json();
  if (!transcriptResponse.ok) {
    throw new Error(transcriptBody.error?.message || "Something went wrong!");
  }

  if (!Array.isArray(transcriptBody.value) || !transcriptBody.value[0]) {
    throw new Error("This meeting doesn't have any transcript.");
  }

  // TODO: Use multiple transcript for better experience
  const transcriptContentResponse = await fetch(transcriptBody.value[0].transcriptContentUrl, {
    headers: {
      Accept: "text/vtt",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!transcriptContentResponse.ok) {
    throw new Error("Unable to fetch the content of the transcript.");
  }

  const transcriptContent = await transcriptContentResponse.text();

  // TODO: Tiktoken integration

  return transcriptContent;
}
